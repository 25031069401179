.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.side-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 15vw;
  height: 100vh;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.2);
}

.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: whitesmoke;
  background-color: #025171;
}

.side-bar-header h1 {
  margin: 0;
  font-size: 36pt;
}

.side-bar-header img {
  max-width: 100%;
  height: auto;
}

.side-bar-body {
  padding-left: 10px;
  flex-grow: 1;
}

.side-bar-signin-button {
  width: 100%;
  padding: 10px;
  align-self: flex-end;
  display: flex;
  justify-content: center;
}

.api-link {
  margin-top: 10px;
  font-size: 12pt;
  transition: all .1s ease;
  padding-left: 10px;
  color: #3b4151;
  font-family: sans-serif;
}

.api-link:hover {
  cursor: pointer;
  border-left: 5px solid crimson;
  transition: all .1s ease;
}

#api-data {
  width: 90vw;
  padding-left: 30px;
  padding-top: 30px;
  overflow-y: scroll;
}

.version-dropdown-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1460px;
  padding: 0 20px;
  width: 100%;
}

.version-label {
  margin-right: 10px;
  font-weight: bold;
  color: #3b4151;
}

.version-dropdown {
  border: 2px solid #41444e;
  padding: 5px;
  border-radius: 4px;
  background: #f7f7f7 right 10px center no-repeat;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.25);
  font-size: 14px;
  font-weight: 700;
}

.login-button-container {
  top: 10px;
  right: 10px;
  z-index: 10;
}

.azure-login-button {
  background-color: #0078d4; /* Azure blue */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.azure-login-button:hover {
  background-color: #005a9e; /* Darker azure blue */
  color: white;
}
